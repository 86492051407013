import { render, staticRenderFns } from "./RoleManages.vue?vue&type=template&id=2758ccef&scoped=true"
import script from "./RoleManages.vue?vue&type=script&lang=js"
export * from "./RoleManages.vue?vue&type=script&lang=js"
import style0 from "./RoleManages.vue?vue&type=style&index=0&id=2758ccef&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2758ccef",
  null
  
)

export default component.exports