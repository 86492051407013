<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
    <ayl-table :table = "table">
      <div slot="ctrl-button">
        <el-button type="success" icon="el-icon-plus" @click="$router.push('/system-manage/addRole')">新增</el-button>
        <!-- <div class="import-btn ml15px">导入</div> -->
        <div class="export-btn ml15px" @click="dialogExport=true">导出</div>
      </div>
    </ayl-table>
    </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [{name: "系统管理", path: ""}, {name: "权限分配", path: ""}],
        list: [],
        dialogExport: false,
        btnLoading: false,
        tableLoading: false,
        checkList: [],
        checkIndex: [],
        table: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api.getList,
          // query 查询当前列表页数据的参数
          query: {
            gmtCreateStart: '',
            gmtCreateEnd: '',
            // gmtModifiedStart: '',
            // gmtModifiedEnd: '',
            status: null,           //请传“使用中”或“已禁用”，如果表示全部状态，请 传null或者空字符串
            queryContent: null,
			// currentPage: 1,
			// pageSize: 10,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
          //   {
          //   type: 'select',
          //   title: '状态',
          //   model: 'status',
          //   placeholder: '请选择',
          //   option: this.$enums.statusList.allList(),
          // },
          {
            type: 'dateSection',
            title: '创建时间',
            startDate: null,
            endDate: null,
            modelName: ['gmtCreateStart', 'gmtCreateEnd'],
          },
          //  {
          //   type: 'dateSection',
          //   title: '更新时间',
          //   startDate: null,
          //   endDate: null,
          //   modelName: ['gmtModifiedStart', 'gmtModifiedEnd'],
          // },
          {
            type: 'input',
            model: 'queryContent',
            placeholder: '角色名称、创建人'
          }],
          columns: [
          //  需要多选
          //   {
          //   type: 'selection'
          // },
          {
            title: '序号',
            width: '50px',
            $index: 'index',
          },
          {
            title: '角色名称',
            key: 'roleName',
			     // width: '150px'
          },
          // {
          //   title: '角色编号',
          //   key: 'roleSerial',
          // },
          {
            title: '创建时间',
            key: 'gmtCreate',
            filter: 'str2ymd',
            //width: '180px'
          }, {
            title: '创建人',
            key: 'creator',
            width: '120px'
          },
          // {
          //   title: '状态',
          //   key: 'status',
          //   enumType: 'Status',
          //   width: '120px'
          // },
           {
            title: '已分配用户数',
            key: 'applied',
            width: '120px'
          },  {
            title: '更新时间',
            key: 'gmtModified',
            filter: 'str2ymd',
            //width: '150px'
          }, {
            title: '操作',
			      width: '200px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class:{
                    'table-view' : true,
                  },
                  on: {
                    click: vm.goDetail.bind(this, ctx.row)
                  }
                }, '查看'),
                h('span', {
                  class:{
                    'table-view' : ctx.row.allowEdit,
					          'table-view-edit' : !ctx.row.allowEdit
                  },
                  on: {
                    click: ctx.row.allowEdit ? vm.goEdit.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                  }
                }, '编辑'),
                h('span', {
                  class: {
                    'table-delete': ctx.row.allowDelete,
					          'table-view-disable': !ctx.row.allowDelete
                  },
                  on: {
                    click: ctx.row.allowDelete ? vm.delete.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                  }
                }, '删除')
              ])
            }
          }]
        }
      }
    },
    methods:{
      goDetail(val){
        this.$router.push({
          path: '/system-manage/roleManageDetails',
          query: {
          roleIdentity: val.roleSerial
          }
        })
      },
      goEdit(val){
        this.$api.getDetail({
          roleIdentity: val.roleSerial
        }).then((res) =>{
          //console.log('res'+JSON.stringify(res))
          this.$router.push({
            path: '/system-manage/roleManageEdit',
            query: {
            roleIdentity: val.roleSerial,
            roleName: res.roleName,
            status: res.status,
            permissionList: res.permissionList,
            remark: res.remark,
            checkedList: res.checkedList
            }
          })
        })
      },
	  //置灰空方法
	  goNull(){},
      async delete(val){
		// this.$confirm(`您确认删除驾驶员【${val.roleName}】吗?`, '提示', {
  //         confirmButtonText: '确定',
  //         cancelButtonText: '取消',
  //         type: 'warning'
  //       }).then(() => {
		//   this.$api.getDelete({
		//     roleIdentity: val.roleSerial,
		//   })
		// }).then(() =>{
  //         this.$notify({
  //           type: 'success',
  //           message: '删除成功!'
  //         });
		// }).then(() =>{
		//   this.$search(this.table)
  //       }).catch(() => {
  //         this.$message({
  //           type: 'info',
  //           message: '已取消删除'
  //         });
  //       });

		await this.$confirm(`您确认删除【${val.roleName}】吗?`, '提示', {
		  confirmButtonText: '确定',
		  cancelButtonText: '取消',
		  type: 'warning'
		})
		await this.$api.getDelete({
		  roleIdentity: val.roleSerial,
		})
		await this.$notify({
		  title: '成功',
		  message: '删除成功',
		  type: 'success'
		})
      await this.$search(this.table)
    },
    //导出
      async exportExcel(){
        this.dialogExport = true
        try {
          const url = await this.$api.listRoleInfoExport({
            //userType: this.table.query.userType,
            gmtCreateStart: this.table.query.gmtCreateStart,
            gmtCreateEnd: this.table.query.gmtCreateEnd,
            // gmtModifiedStart: this.table.query.gmtModifiedStart,
            // gmtModifiedEnd: this.table.query.gmtModifiedEnd,
            // status: this.table.query.status,
            queryContent: this.table.query.queryContent,
            excelKey: 'listRoleInfoExport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
    },
    async mounted () {
      await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listRoleInfoExport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    }
  }
</script>

<style lang='sass' scoped>
/deep/ .table-view-disable
  padding: 0px 10px
  color: #ff999c
  border-left: 1px solid $grayShallow3
/deep/ .table-view-edit
  padding-right: 10px
  color: #99c0ff
</style>
